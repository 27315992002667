import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { ChargeHeaderSearchCriteria } from "presentation/constant/ChargeHeader/ChargeHeaderSearchCriteria";
import { DeliveryChargeSummarySearchCriteria } from "presentation/constant/ChargeHeader/DeliveryChargeSummary/DeliveryChargeSummarySearchCriteria";
import { useDeliveryChargeSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useDeliveryChargeSummaryMaintenanceVM";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useDeliveryChargeSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/DeliveryChargeSummaryMaintenanceProvider";
import { useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { DeliveryChargeSummarySearchCriteriaPanel } from "./DeliveryChargeSummarySearchCriteriaPanel";
import { DeliveryChargeSummaryTablePanel } from "./DeliveryChargeSummaryTablePanel";
import DeliveryChargeSummaryTitleBar from "./DeliveryChargeSummaryTitleBar";


export const DeliveryChargeSummaryComp: React.FC = () => {
    const [deliverySummaryState] = useDeliveryChargeSummaryMaintenanceTracked();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    const isShowCriteriaPanel = deliverySummaryState.isShowCriteriaPanel;
    const [initiateScreen, setInitiateScreen] = useState<boolean>(false);
    const devChgMainVM = useDeliveryChargeSummaryMaintenanceVM();    

    useEffect(() => {
        if (initiateScreen) return;
        setIsLoading(true);

        const cacheSearchCriteria = localStorage.getItem("ChargeHeaderSearchCriteria");
        let deliveryCriteria:DeliveryChargeSummarySearchCriteria
        if (cacheSearchCriteria) {
            const headerSearchCriteira = JSON.parse(cacheSearchCriteria) as ChargeHeaderSearchCriteria;
            deliveryCriteria = {        
                soa: headerSearchCriteira?.soa,
                vesselCode: headerSearchCriteira?.vesselCode,
                voyageCode: headerSearchCriteira?.voyageCode,
                vesselName: headerSearchCriteira?.vesselName,
                billingCycleList: headerSearchCriteira?.billingCycleList??[],
                billingCycleCode: headerSearchCriteira?.billingCycleCode,
                serviceCodeList: headerSearchCriteira?.serviceCodeList??[],
                etaDateFrom: headerSearchCriteira?.etaDateFrom,
                etaDateTo: headerSearchCriteira?.etaDateTo,
                etdDateFrom: headerSearchCriteira?.etdDateFrom,
                etdDateTo: headerSearchCriteira?.etdDateTo,
                loginUser: headerSearchCriteira?.loginUser,
            }
        } else {
            deliveryCriteria = {
                soa: chargeHeaderState.searchCriteria?.soa,
                vesselCode: chargeHeaderState.searchCriteria?.vesselCode,
                voyageCode: chargeHeaderState.searchCriteria?.voyageCode,
                vesselName: chargeHeaderState.searchCriteria?.vesselName,
                billingCycleList: chargeHeaderState.searchCriteria?.billingCycleList??[],
                billingCycleCode: chargeHeaderState.searchCriteria?.billingCycleCode,
                serviceCodeList: chargeHeaderState.searchCriteria?.serviceCodeList??[],
                etaDateFrom: chargeHeaderState.searchCriteria?.etaDateFrom,
                etaDateTo: chargeHeaderState.searchCriteria?.etaDateTo,
                etdDateFrom: chargeHeaderState.searchCriteria?.etdDateFrom,
                etdDateTo: chargeHeaderState.searchCriteria?.etdDateTo,
                loginUser: chargeHeaderState.searchCriteria?.loginUser,
            }
        }

        const initialScreen = async() => {
            try {                
                const results = await Promise.allSettled([
                    devChgMainVM.onPageLoad(deliveryCriteria),
                    devChgMainVM.loadDropdownOption(),
                    devChgMainVM.onSearch({                            
                            ...deliveryCriteria
                        }, 
                        EMPTY_SEARCH_MODE_ENTITY),
                ]).finally(() => {
                    setInitiateScreen(true);
                });
                results?.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                      } else if (index === 1 && result.status === 'fulfilled') {

                      } else if (index === 2 && result.status === 'fulfilled') {

                      }
                })
            } catch(error) {
            }
        }
        initialScreen().then(data => { 
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        })
    }, [devChgMainVM, initiateScreen, chargeHeaderState.searchCriteria.chargeHdr?.billingCycle, chargeHeaderState.searchCriteria.chargeHdr?.billingCycleCode, 
        chargeHeaderState.searchCriteria.chargeHdr?.serviceCode, chargeHeaderState.searchCriteria.chargeHdr?.shipOwner, 
        chargeHeaderState.searchCriteria.chargeHdr?.vesselCode, chargeHeaderState.searchCriteria.chargeHdr?.vesselName, 
        chargeHeaderState.searchCriteria.chargeHdr?.voyageCode, chargeHeaderState.searchCriteria?.loginUser, chargeHeaderState.searchCriteria?.billingCycleCode, 
        chargeHeaderState.searchCriteria?.billingCycleList, chargeHeaderState.searchCriteria.chargeHdr, chargeHeaderState.searchCriteria?.etdDateFrom, 
        chargeHeaderState.searchCriteria.etdDateTo, chargeHeaderState.searchCriteria?.serviceCodeList, chargeHeaderState.searchCriteria?.soa, 
        chargeHeaderState.searchCriteria?.vesselCode, chargeHeaderState.searchCriteria?.vesselName, chargeHeaderState.searchCriteria?.voyageCode, 
        chargeHeaderState.searchCriteria?.etaDateFrom, chargeHeaderState.searchCriteria?.etaDateTo]);

    return  <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}> 
        {isLoading && <Loader Indicator="Stripe" size="Large" />}
        <DeliveryChargeSummaryTitleBar/>
        <SliderPanel
            isOpen={true}
            draggable={false}
            rightSectionWidth={isShowCriteriaPanel?"80%":"100%"}
            leftSectionWidth={isShowCriteriaPanel?"20%":"0%"}
            leftChildren={<DeliveryChargeSummarySearchCriteriaPanel/>}
            rightChildren={<DeliveryChargeSummaryTablePanel/>}
            />
    </div>
}