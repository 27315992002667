import { EMPTY_SEARCH_MODE_ENTITY, SearchModeEntity } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customEventListener } from "helpers/Events";
import moment from "moment";
import { ChargeHeaderSearchCriteria, EMPTY_CHARGE_HEADER_SEARCH_CRITERIA } from "presentation/constant/ChargeHeader/ChargeHeaderSearchCriteria";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { ChargeHeaderSearchCriteriaPanel } from "./ChargeHeaderSearchPanel/ChargeHeaderSearchCriteriaPanel/ChargeHeaderSearchCriteriaPanel";
import { ChargeHeaderSearchRightPanel } from "./ChargeHeaderSearchPanel/ChargeHeaderSearchRightPanel";
import ChargeHeaderSearchTablePanel from "./ChargeHeaderTablePanel/ChargeHeaderSearchTablePanel";
import { DEFAULT_DATE_RANGE } from "domain/repository/apiUtil";

export function isSearchCriteriaSet(obj1: any, obj2: any) { return JSON.stringify(obj1) === JSON.stringify(obj2); }

export const ChargeHeaderMaintenanceContent: React.FC = () => {
    const [chargeHdrState, setChargeHdrState] = useChargeHeaderMaintenanceTracked();
    const { isShowCriteriaPanel, isShowRightPanel, isShowDivertShipPanel } = chargeHdrState;
    // const searchCounter = chargeHdrState.searchCounter;
    // const openedContainerNo = inventorySearchState.openedContainerNo;
    // const isShowContainerDetail = chargeHdrState.isShowContainerDetail;
    // const isEditSearchCriteria = chargeHdrState.isEditSearchCriteria;
    const [anaInfoState] = useANAInfoTracked();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [initial, setInitial] = useState<boolean>(false);

    // const memoContainerDetailModal = useMemo(() => openedContainerNo ? <ContainerDetail/> : null, [openedContainerNo]);
    const chargeHeaderSearchVM = useChargeHeaderMaintenanceVM();
    const showRightPanel = isShowRightPanel || isShowDivertShipPanel;
    const [initialScreenCompleted, setInitialScreenCompleted] = useState<boolean>(false);

    const memoChargeDataSearchTablePanel = useMemo(() => <ChargeHeaderSearchTablePanel />, []);
    const memoChargeDataSearchRightPanel = useMemo(() => <ChargeHeaderSearchRightPanel />, []);
    const memoChargeDataCriteriaPanel = useMemo(() => <><ChargeHeaderSearchCriteriaPanel /></>, []);

    const initialScreen = useCallback(async() => {
        try {
            if (initialScreenCompleted) return;

            setIsLoading(true);

            const cacheSearchCriteria = localStorage.getItem("ChargeHeaderSearchCriteria");
            let results;
            if (chargeHdrState?.isRedirectFromChargeDetail && cacheSearchCriteria) {
                setInitialScreenCompleted(true);
                results = await Promise.allSettled([
                        chargeHeaderSearchVM.onPageInit(anaInfoState.userName),
                        // chargeHeaderSearchVM.loadAllSearchPreference(),
                        chargeHeaderSearchVM.loadDropdownOption(),
                        chargeHeaderSearchVM.onSearch(
                            JSON.parse(cacheSearchCriteria) as ChargeHeaderSearchCriteria, 
                            EMPTY_SEARCH_MODE_ENTITY)
                    ]).finally(() => {
                        setIsLoading(false);
                    });
                    setChargeHdrState(prevState => {
                        return {
                            ...prevState,
                            isRedirectFromChargeDetail: false,
                        }
                    })
            } else {
                setInitialScreenCompleted(true);
                results = await Promise.allSettled([
                        chargeHeaderSearchVM.onPageInit(anaInfoState.userName),
                        // chargeHeaderSearchVM.loadAllSearchPreference(),
                        chargeHeaderSearchVM.loadDropdownOption(),
                        chargeHeaderSearchVM.onSearch(
                            {
                                ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                                etdDateTo: moment().endOf("day").toDate(),
                                loginUser: anaInfoState.userName,
                                etdDateFrom:  moment().subtract(DEFAULT_DATE_RANGE, "days").startOf("day").toDate(),
                                screenInited: true,
                            }, 
                            EMPTY_SEARCH_MODE_ENTITY)
                    ]).finally(() => {
                        setIsLoading(false);
                    });
            }
            results?.forEach((result, index) => {
                if (index === 0 && result.status === 'fulfilled') {

                  } else if (index === 1 && result.status === 'fulfilled') {

                  } else if (index === 2 && result.status === 'fulfilled') {

                  }
            })
        } catch(error) {
        }
    }, [anaInfoState.userName, chargeHeaderSearchVM, initialScreenCompleted, chargeHdrState?.isRedirectFromChargeDetail, setChargeHdrState]);

    const initialRedirectScreenByChargeDetail = useCallback(async() => {
        const vesselSummaryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY)??"") :
            null;
        const inboundSummaryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY)??"") :
            null;
        const deliveryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY)??"") :
            null;
        if (!vesselSummaryData && !inboundSummaryData && !deliveryData) return;
        
        chargeHeaderSearchVM.onShowLoading();

        const chargeHeaderSearchCriteria:ChargeHeaderSearchCriteria = {
            ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
            id: Number((vesselSummaryData??inboundSummaryData??deliveryData)?.chargeHdrId)??0,
            hdrGroupName: deliveryData ? "SHIPBOUNDEDINPERIOD" : "",
            tempHdrGroupName: deliveryData ? "SHIPBOUNDEDINPERIOD" : "",
            loginUser: deliveryData ? anaInfoState.userName : "", 
            screenInited: true,
            type: deliveryData ? "deliveryChargeSummary" : null,
        };
        const searchModeEntity:SearchModeEntity = EMPTY_SEARCH_MODE_ENTITY;                          

        const results = await Promise.allSettled([
            // chargeHeaderSearchVM.loadAllSearchPreference(),
            chargeHeaderSearchVM.loadDropdownOption(),
            chargeHeaderSearchVM.onSearch(chargeHeaderSearchCriteria, searchModeEntity, true, true).then(data => {
                if (data) {
                    const chargeHdr = data[0];
                    setChargeHdrState(prevState => ({
                        ...prevState,
                        currentChargeHeader: {
                            ...prevState.currentChargeHeader,
                            ...chargeHdr
                        },
                        searchCriteria: {
                            ...chargeHeaderSearchCriteria,
                            chargeHdr: chargeHdr
                        },
                        isShowCloseIcon: false
                    }));
                }
            }),            
        ]).finally(() => {
            chargeHeaderSearchVM.onHideLoading();
            setInitial(false);
            if (vesselSummaryData) {
                chargeHeaderSearchVM.goToVesselSummary();
                localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY);
            }
            if (inboundSummaryData) {
                chargeHeaderSearchVM.goToInboundContainerSummary();            
                localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY);
            }
            if (deliveryData) {
                chargeHeaderSearchVM.goToDeliveryChargeSummary();            
                localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY);
            }
        });
        results?.forEach((result, index) => {
            if (index === 0 && result.status === 'fulfilled') {

            } else if (index === 1 && result.status === 'fulfilled') {

            } else if (index === 2 ) {
                if (result.status === 'fulfilled') {

                }
            }
        });
        
    }, [chargeHeaderSearchVM, setChargeHdrState, anaInfoState.userName]);

    const memoLeftPanel = useMemo(() => {
        return <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftChildren={memoChargeDataCriteriaPanel}
                rightChildren={memoChargeDataSearchTablePanel}
                leftSectionWidth={isShowCriteriaPanel?'25%':"0%"}
                rightSectionWidth={isShowCriteriaPanel?'75%':"100%"} />
        </div>
    }, [memoChargeDataCriteriaPanel, memoChargeDataSearchTablePanel, isShowCriteriaPanel])

    const memoResizablePanel = useMemo(() => {
        return <SliderPanel
            isOpen={true}
            draggable={false}
            leftChildren={memoLeftPanel}
            rightChildren={memoChargeDataSearchRightPanel}
            leftSectionWidth={showRightPanel?'75%':"100%"}
            rightSectionWidth={showRightPanel?'25%':"0%"} />
        }, [memoLeftPanel, memoChargeDataSearchRightPanel, showRightPanel]);

    // Charge header search event listner
    const onChargeHeaderSearchChange = (e: any) => {
        switch (e.detail.action) {
            case E_Custom_Dispatch_Event.CHARGE_HEADER_EDIT_CRITERIA:
                chargeHeaderSearchVM.onEditSearchCriteria();
                break;
            case E_Custom_Dispatch_Event.CHARGE_HEADER_OPEN_DIRECTORY:
                chargeHeaderSearchVM.onSwitchSearchCriteriaMode();
                break;
            // case E_Custom_Dispatch_Event.CHARGE_HEADER_PREFERENCE_SAVE_SEARCH:
            //     chargeHeaderSearchVM.saveSearchPreference(e.detail.data.currentSearchPreference, e.detail.data.searchCriteria, e.detail.data.enabledSearchCritieria);
            //     break;
            case E_Custom_Dispatch_Event.CHARGE_HEADER_PREFERENCE_SAVE_NEW:
                chargeHeaderSearchVM.openSaveNewConfirmModal();
                break;
            case E_Custom_Dispatch_Event.CHARGE_HEADER_PREFERENCE_DELETE:
                chargeHeaderSearchVM.openDeleteConfirmModal();
                break;
            // case E_Custom_Dispatch_Event.CHARGE_HEADER_PREFERENCE_OPEN:
            //     chargeHeaderSearchVM.loadSearchPreference(e.detail.data.searchPreference);
            //     break;
            // case E_Custom_Dispatch_Event.CHARGE_HEADER_MODAL_BACK_TO_LIST:
            //     chargeDataSearchVM.closeContainerDetail();
            //     break;
        }
    }

    useEffect(() => {
        customEventListener(E_Type_Of_Event.CHARGE_HEADER_SEARCH_EVENT, onChargeHeaderSearchChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const onChargeHeaderRedirect = async(e:any) => {
            switch (e.detail.action) {
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY:                                        
                    const vesselData = e.detail.data.data;
                    localStorage.setItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY, JSON.stringify(vesselData));
                    setInitial(true);                  
                    
                    break;
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY:                                        
                    const inboundData = e.detail.data.data;
                    localStorage.setItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY, JSON.stringify(inboundData));
                    setInitial(true);                  
                    
                    break;
                case E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY:                                        
                    const deliveryData = e.detail.data.data;
                    localStorage.setItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY, JSON.stringify(deliveryData));
                    setInitial(true);                  
                    
                    break;
            }
        }

        customEventListener(E_Type_Of_Event.CHARGE_HEADER_REDIRECT_EVENT_INITIAL, onChargeHeaderRedirect);
    }, [chargeHeaderSearchVM, setChargeHdrState]);


    //redirect back to charge header
    useEffect(() => {
        const vesselSummaryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY)??"") :
            null;
        const inboundSummaryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY)??"") :
            null;
        const deliverySummaryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY)??"") :
            null;
        if (!vesselSummaryData && !inboundSummaryData && !deliverySummaryData) {
            initialScreen();
        } else {
            initialRedirectScreenByChargeDetail();
        }
    }, [initialScreen, initialRedirectScreenByChargeDetail, initial, chargeHeaderSearchVM, setChargeHdrState]);


    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <div className={`main-comp-wrapper${showRightPanel ? '' : ' im-hide-side-form-draggable'}`}>
        {chargeHdrState.isLoading && <Loader Indicator="Spinner" size="Large" />}
        {memoResizablePanel}
    </div>

    // return <div className={`charge-data-box${((searchCounter > 0 && !showRightPanel) || isShowContainerDetail) ? ' no-filter-preview' : ' filter-preview'}${isShowContainerDetail ? ' show-detail' : ''}`}>
    //     {/* Show container search panel */}
    //     {/* <div style={{ display: !isShowContainerDetail ? 'block' : 'none' }}>{memoResizablePanel}</div> */}
    //     {memoResizablePanel}
    //     {
    //         chargeHdrState.isLoading && <Loader Indicator="Spinner" size="Large" />
    //     }
    //     <div className='im-charge-data-search-confirm-modal-container'>
    //         <SaveNewPreferenceModal visible={chargeHdrState.isShowSaveNewConfirmModal} />
    //         <DeletePreferenceModal visible={chargeHdrState.isShowDeleteConfirmModal} />
    //     </div>
    // </div>;
}
