
export interface ChargeDetailEntity{
    key: string,
    versionIdentifier: {
        version?: string
    },
    dtlState: string | null,
    bundleInd?: string | null,
    billingType?: string | null,
    bundleNumber?: string | null,
    bundleNumberStr?: string | null,
    fromTml?: string | null,
    toTml?: string | null,
    tsLegChargeInd?: string | null,
    manualTsLegChargeInd?: string | null,
    seq?: number | null,
    serviceCode?: string | null,
    cmAcPRate?: string | null,
    chargeType?: string | null,
    subChargeType?: string | null,
    chargeInd?: string | null,
    opsLine?: string | null,
    forwarderCode1?: string | null,
    chargeOnCompany?: string | null,
    billToCompany?: string | null,
    cntrNo?: string | null,
    status?: string | null,
    status2?: string | null,
    size?: number | null,
    operatingTml?: string | null,
    poo?: string | null,
    pol?: string | null,
    pod?: string | null,
    fd?: string | null,
    consortiumCode?: string | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
    arrivalConsortiumCode?: string | null,
    arrivalVesselCode?: string | null,
    arrivalVoyageCode?: string | null,
    arrivalServiceCode?: string | null,
    arrivalVesselName?: string | null,
    departureConsortiumCode?: string | null,
    departureVesselCode?: string | null,
    departureVoyageCode?: string | null,
    departureServiceCode?: string | null,
    departureVesselName?: string | null,
    depDatetime?: Date | null,
    arrDatetime?: Date | null,
    vesselName?: string | null,
    bargeJobId?: string | null,
    receiveDateTime?: Date | null,
    deliveryDate?: Date | null,
    voyageOperatingTml?: string | null,
    voyageServiceCode?: string | null,
    soa?: string | null,
    tariffType?: string | null,
    tariffCode?: string | null,
    qty?: number | null,
    rate?: number | null,
    directInd?: string | null,
    specialInd?: string | null,
    specialHandlingCode?: string | null,
    specialHandlingInd?: string | null,
    equipmentCode?: string | null,
    grossWt?: number | null,
    imcoCode?: string | null,
    imcoCode2?: string | null,
    imcoCode3?: string | null,
    duContent?: string | null,
    voltage?: number | null,
    inYardDay?: number | null,
    rfTemp?: number | null,
    rfTempUnit?: string | null,
    permitNo?: string | null,
    reference?: string | null,
    loadingDatetime?: Date | null,
    dischargeDatetime?: Date | null,
    gateInDatetime?: Date | null,
    origReceivalDatetime?: Date | null,
    nonCellularVesselInd?: string | null,
    wireSlingHandlingInd?: string | null,
    shiftCode?: string | null,
    surchargePercentage?: number | null,
    soNumber1?: string | null,
    cargoCode?: string | null,
    trainId?: string | null,
    tractorNo?: string | null,
    forwarderReference?: string | null,
    ohDimen?: number | null,
    olDimen?: number | null,
    owDimen?: number | null,
    pooGeogInd?: string | null,
    polGeogInd?: string | null,
    podGeogInd?: string | null,
    fdGeogInd?: string | null,
    pooCountry?: string | null,
    polCountry?: string | null,
    podCountry?: string | null,
    fdCountry?: string | null,
    invoiceLeg?: string | null,
    cmAcPRatePercentage?: number | null,
    otherLegTsLegChargeInd?: string | null,
    reasonCode?: string | null,
    invoiceNo?: string | null,
    draftInvoiceNo?: string | null,
    draftCreditNo?: string | null,
    mcNo?: string | null,
    crNo?: string | null,
    creditNoteNo?: string | null,
    adjType?: string | null,
    exceptionMsg?: string | null,
    handlingDatetime?: Date | null,
    shipmentType?: string | null,
    cntrType?: string | null,
    offStdBackLength?: number | null,
    offStdFrontLength?: number | null,
    offStdLeftWidth?: number | null,
    offStdRightWidth?: number | null,
    itemDesc?: string | null,
    gateClosingDatetime?: Date | null,
    modalityOfArrival?: string | null,
    modalityOfDeparture?: string | null,
    customerSizeType?: string | null,
    marshalFrom?: string | null,
    marshalTo?: string | null,
    overtimeInd?: string | null,
    overWeightInd?: string | null,
    overDimensionInd?: string | null,
    equipmentCodeInd?: string | null,
    emptyIndicator?: string | null,
    partnerCode?: string | null,
    cntrTspStatus?: string | null,
    operationType?: string | null,
    outTerminalInd?: string | null,
    outTmlDatetime?: Date | null,
    offStdMeasurementMode?: string | null,
    slVesselCode?: string | null,
    slIbVoyageCode?: string | null,
    slObVoyageCode?: string | null,
    alterableChargeOnCompany?: string | null,
    alterableBillToCompany?: string | null,
    handlingTerminal?: string | null,
    ignoreCntrStatusValidation?: string | null,
    cokdOpsDatetime?: Date | null,
    cokdStatus?: string | null,
    commonAcType?: string | null,
    cmAcFixQty?: number | null,
    reDirectFromTml?: string | null,
    legSpHandlingCode?: string | null,
    legSpHandlingInd?: string | null,
    rebillFrom?: string | null,
    dgContent?: string | null,
    //cy vessel
    berthingTml?: string | null,
    fromDate?: Date | null,
    toDate?: Date | null,
    noOfQuayCrane?: number | null,
    shiftCodeOps?: string | null,
    gangId?: string | null,
    intensivePercentage?: string | null,
    operationStartDatetime?: Date | null,
    operationEndDatetime?: Date | null,
    delayReasonCode?: string | null,
    delayRemarks?: string | null,
    laGraceMins?: number | null,
    quayCraneId?: string | null,
    voyEquipmentHandlingCode?: string | null,
    //YH
    owner1?: string | null,
    etd?: Date | null,
    etb?: Date | null,
    chargePercentage?: string | null,
    soNo?: string | null,
    chargeCode?: string | null,
    wagonNo?: string | null,
    trainAtb?: Date | null,
    trainAtd?: Date | null,
    driverId?: string | null,
    driverName?: string | null,
    gateOutDatetime?: Date | null,
    //ITM
    storageDayNum?: number | null,
    storageMode?: string | null,
    expiryDate?: Date | null,
    inYardPeriod?: string | null,
    secondTierFsp?: string | null,
    adjChargeQty?: number | null,
    calendarAdjChargeQty?: number | null,
    manualAdjChargeQty?: number | null,
    manualAdjChargeQtyUom?: string | null,
    cashChargeQty?: number | null,
    chargeQty?: number | null,
    chargeQtyUom?: string | null,
    cashAmount?: number | null,
    cashReceiptNo?: string | null,
    waiverNo?: string | null,
    firstTierFsp?: string | null,
    storageChargeQty?: number | null,
    //EMP
    opsDate?: Date | null,
    alliance?: string | null,
    master?: string | null,
    calculateBy?: string | null,
    qty20?: number | null,
    qty40?: number | null,
    qty45?: number | null,
    qty48?: number | null,
    qty53?: number | null,
    teuQty?: number | null,
    free1ChargeQty?: number | null,
    free2ChargeQty?: number | null,
    pool1ChargeQty?: number | null,
    pool2ChargeQty?: number | null,
    excessChargeQty?: number | null,
    accountTmlReasonCode?: string | null,
    //OBS
    obCntrCloseDatetime?: Date | null,
    account?: string | null,
    //GS-INTERIM
    changeDatetime?: Date | null,
    firstDischargeDateTime?: Date | null,
    lastDischargeDateTime?: Date | null,
    firstLoadingDateTime?: Date | null,
    lastLoadingDateTime?: Date | null,
    //RFH
    reeferHandlingType?: string | null,
    createDatetime?: Date | null,
    requestDateTime?: Date | null,
    ptPlugOnDatetime?: Date | null,
    ptPlugOffDatetime?: Date | null,
    pcPlugOnDatetime?: Date | null,
    pcPlugOffDatetime?: Date | null,
    telexNo?: string | null,
    //General Charge
    agentCode?: string | null,
    vesselType?: string | null,
    marshallingType?: string | null,
    cargoType?: string | null,
    palletizedIndicator?: string | null,
    //TS
    otherEtb?: Date | null,
    otherOperatingTml?: string | null,
    vesselInfo?: string | null,
    otherServiceCode?: string | null,
    otherChargeOnCompany?: string | null,
    otherSpecialInd?: string | null,
    otherDirectInd?: string | null,
    otherEtd?: Date | null,
    otherFirstDischargeDatetime?: Date | null,
    otherLastDischargeDatetime?: Date | null,
    //MAR
    marshallingGroundDatetime?: Date | null,
    chargeToAccountCode?: string | null,

    rateType?: string | null,
    accResumeTerminalInd?: string | null,
    oneOffRateInd?: string | null,
    holdInd?: string | null,
    releaseInd?: string | null,
    masterHoldId?: number | null,
    releaseMasterHoldId?: number | null,
    storageFromDate?: Date | null,
    storageToDate?: Date | null,
    commonAcSeq?: Object | null,
    co?: string | null,
    vsl?: string | null,
    voy?: string | null,	
    arrivalDatetime?: Date | null,
    departureDatetime?: Date | null,
	loa?: number | null,
	vslStartOperationDatetime?: Date | null,	
	vslEndOperationDatetime?: Date | null,	
	vslScale?: string | null,	
	eta?: Date | null,	
	manualChangeVesslInd?: string | null,
    companyCode?: string | null,
    paymentDueDate?: Date | null,
    operatingCompany?: string | null,
	chargeShareType?: string | null,
    voyageFinalizedInd?: string | null,
    chargeHdrId?: number | null,
    totalInTeu?: number | null,
    totalInItem?: number | null,
	excessPoolInTeu?: number | null,
	excessPoolInItem?: number | null,
    poolQtyUom?: string | null,

    updateCntrInfo?: boolean | null,
    updateEmptyIndicator?: boolean | null,
    
	rebillChargeId?: number | null,
	previousChargeDetailId?: number | null,
	cntrVisitId?: string | null,
	bundleVisitId?: string | null,
    oldBundleNumber?: string | null,
    oldBundleVisitId?: string | null
    bundleNumberUpdatedInd?: string | null,
    poNo?: string | null,
    ourRef?: string | null,
    yourRef?: string | null,
    creditNoteNoVal?: string | null,
    invoiceNoVal?: string | null,
    draftInvoiceNoVal?: string | null,
    draftCreditNoVal?: string | null,

    [key: string]: string | boolean | null | Object | undefined
}


export const EMPTY_CHARGE_DETAIL_ENTITY : ChargeDetailEntity = {
    key: "",
    versionIdentifier: {},
    dtlState: null,
    bundleInd: null,
    billingType: null,
    bundleNumber: null,
    bundleNumberStr:null,
    fromTml: null,
    toTml: null,
    tsLegChargeInd: null,
    manualTsLegChargeInd: null,
    seq: null,
    serviceCode: null,
    cmAcPRate: null,
    chargeType: null,
    subChargeType: null,
    chargeInd: null,
    opsLine: null,
    forwarderCode1: null,
    chargeOnCompany: null,
    billToCompany: null,
    cntrNo: null,
    status: null,
    size: null,
    operatingTml: null,
    poo: null,
    pol: null,
    pod: null,
    fd: null,
    consortiumCode: null,
    vesselCode: null,
    voyageCode: null,
    arrivalConsortiumCode: null,
    arrivalVesselCode: null,
    arrivalVoyageCode: null,
    arrivalServiceCode: null,
    arrivalVesselName: null,
    departureConsortiumCode: null,
    departureVesselCode: null,
    departureVoyageCode: null,
    departureServiceCode: null,
    departureVesselName: null,
    depDatetime: null,
    arrDatetime: null,
    vesselName: null,
    bargeJobId: null,
    receiveDateTime: null,
    deliveryDate: null,
    voyageOperatingTml: null,
    voyageServiceCode: null,
    soa: null,
    tariffType: null,
    tariffCode: null,
    qty: null,
    rate: null,
    directInd: null,
    specialInd: null,
    specialHandlingCode: null,
    specialHandlingInd: null,
    equipmentCode: null,
    grossWt: null,
    imcoCode: null,
    imcoCode2: null,
    imcoCode3: null,
    duContent: null,
    voltage: null,
    inYardDay: null,
    rfTemp: null,
    rfTempUnit: null,
    permitNo: null,
    reference: null,
    loadingDatetime: null,
    dischargeDatetime: null,
    gateInDatetime: null,
    origReceivalDatetime: null,
    nonCellularVesselInd: null,
    wireSlingHandlingInd: null,
    shiftCode: null,
    surchargePercentage: null,
    soNumber1: null,
    cargoCode: null,
    trainId: null,
    tractorNo: null,
    forwarderReference: null,
    ohDimen: null,
    olDimen: null,
    owDimen: null,
    pooGeogInd: null,
    polGeogInd: null,
    podGeogInd: null,
    fdGeogInd: null,
    pooCountry: null,
    polCountry: null,
    podCountry: null,
    fdCountry: null,
    invoiceLeg: null,
    cmAcPRatePercentage: null,
    otherLegTsLegChargeInd: null,
    reasonCode: null,
    invoiceNo: null,
    draftInvoiceNo: null,
    draftCreditNo: null,
    mcNo: null,
    crNo: null,
    creditNoteNo: null,
    adjType: null,
    exceptionMsg: null,
    handlingDatetime: null,
    shipmentType: null,
    cntrType: null,
    offStdBackLength: null,
    offStdFrontLength: null,
    offStdLeftWidth: null,
    offStdRightWidth: null,
    itemDesc: null,
    gateClosingDatetime: null,
    modalityOfArrival: null,
    modalityOfDeparture: null,
    customerSizeType: null,
    marshalFrom: null,
    marshalTo: null,
    overtimeInd: null,
    overWeightInd: null,
    overDimensionInd: null,
    equipmentCodeInd: null,
    emptyIndicator: null,
    partnerCode: null,
    cntrTspStatus: null,
    operationType: null,
    outTerminalInd: null,
    outTmlDatetime: null,
    offStdMeasurementMode: null,
    slVesselCode: null,
    slIbVoyageCode: null,
    slObVoyageCode: null,
    alterableChargeOnCompany: null,
    alterableBillToCompany: null,
    handlingTerminal: null,
    ignoreCntrStatusValidation: null,
    cokdOpsDatetime: null,
    cokdStatus: null,
    commonAcType: null,
    cmAcFixQty: null,
    reDirectFromTml: null,
    legSpHandlingCode: null,
    legSpHandlingInd: null,
    rebillFrom: null,
    dgContent: null,
    berthingTml: null,
    fromDate: null,
    toDate: null,
    noOfQuayCrane: null,
    shiftCodeOps: null,
    gangId: null,
    intensivePercentage: null,
    operationStartDatetime: null,
    operationEndDatetime: null,
    delayReasonCode: null,
    delayRemarks: null,
    laGraceMins: null,
    quayCraneId: null,
    voyEquipmentHandlingCode: null,
    owner1: null,
    etd: null,
    etb: null,
    chargePercentage: null,
    soNo: null,
    chargeCode: null,
    wagonNo: null,
    trainAtb: null,
    trainAtd: null,
    driverId: null,
    driverName: null,
    gateOutDatetime: null,
    storageDayNum: null,
    storageMode: null,
    expiryDate: null,
    inYardPeriod: null,
    secondTierFsp: null,
    adjChargeQty: null,
    calendarAdjChargeQty: null,
    manualAdjChargeQty: null,
    manualAdjChargeQtyUom: null,
    cashChargeQty: null,
    chargeQty: null,
    chargeQtyUom: null,
    cashAmount: null,
    cashReceiptNo: null,
    waiverNo: null,
    firstTierFsp: null,
    storageChargeQty: null,
    opsDate: null,
    alliance: null,
    master: null,
    calculateBy: null,
    qty20: null,
    qty40: null,
    qty45: null,
    qty48: null,
    qty53: null,
    teuQty: null,
    free1ChargeQty: null,
    free2ChargeQty: null,
    pool1ChargeQty: null,
    pool2ChargeQty: null,
    excessChargeQty: null,
    accountTmlReasonCode: null,
    obCntrCloseDatetime: null,
    account: null,
    changeDatetime: null,
    firstDischargeDateTime: null,
    lastDischargeDateTime: null,
    firstLoadingDateTime: null,
    lastLoadingDateTime: null,
    reeferHandlingType: null,
    createDatetime: null,
    requestDateTime: null,
    ptPlugOnDatetime: null,
    ptPlugOffDatetime: null,
    pcPlugOnDatetime: null,
    pcPlugOffDatetime: null,
    telexNo: null,
    agentCode: null,
    vesselType: null,
    marshallingType: null,
    cargoType: null,
    palletizedIndicator: null,
    otherEtb: null,
    otherOperatingTml: null,
    vesselInfo: null,
    otherServiceCode: null,
    otherChargeOnCompany: null,
    otherSpecialInd: null,
    otherDirectInd: null,
    otherEtd: null,
    otherFirstDischargeDatetime: null,
    otherLastDischargeDatetime: null,
    marshallingGroundDatetime: null,
    chargeToAccountCode: null,
    rateType: null,
    accResumeTerminalInd: null,
    oneOffRateInd: null,
    holdInd: null,
    releaseInd: null,
    masterHoldId: null,
    releaseMasterHoldId: null,
    storageFromDate: null,
    storageToDate: null,
    commonAcSeq: null,
    co: null,
    vsl: null,
    voy: null,	
    arrivalDatetime: null,
    departureDatetime: null,
    loa: null,	
	vslStartOperationDatetime: null,	
	vslEndOperationDatetime: null,	
	vslScale: null,	
	eta: null,	
	manualChangeVesslInd: null,	
    companyCode: null,	
    paymentDueDate: null,
    operatingCompany: null,
	chargeShareType: null,
    voyageFinalizedInd: null,
    chargeHdrId: null,
    totalInTeu: null,
    totalInItem: null,
	excessPoolInTeu: null,
	excessPoolInItem: null,
    poolQtyUom: null,

    updateCntrInfo: false,
    updateEmptyIndicator: false,
    
	rebillChargeId: null,
	previousChargeDetailId: null,
	cntrVisitId: null,
    
	bundleVisitId: null,
    oldBundleNumber: null,
    oldBundleVisitId: null,
    bundleNumberUpdatedInd: "N",
    poNo: null,
    ourRef: null,
    yourRef: null,
    creditNoteNoVal: null,
    invoiceNoVal: null,
    draftInvoiceNoVal: null,
    draftCreditNoVal: null
}