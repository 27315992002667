import { SelectionChangedEvent } from "ag-grid-community";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { INITIAL_DOMESTIC_CHARGE_HEADER_COL_DEF, INITIAL_SHIPBOUNDEDINPERIOD_CHARGE_HEADER_COL_DEF, INITIAL_SHIPBOUNDED_CHARGE_HEADER_COL_DEF, transferShipboundedRowData } from "presentation/constant/ChargeHeader/ChargeHeaderContainerColumnDefinition";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { Suspense, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

const ChargeHeaderSearchTablePanel = () => {
    const [chargeDataSearchState] = useChargeHeaderMaintenanceTracked();
    const searchCriteria = chargeDataSearchState.searchCriteria;
    const chargeHeaderMainVM = useChargeHeaderMaintenanceVM();
    let gridRef: any = useRef();
    const tableId = "chargeHeaderTable"
    const { hdrGroupName } = searchCriteria;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    const getCurrentTableColumnDefs = useCallback(() => {
        const columnDefs = ("DOMESTIC" === searchCriteria?.hdrGroupName ? INITIAL_DOMESTIC_CHARGE_HEADER_COL_DEF?.slice() :
            ("SHIPBOUNDED" === searchCriteria?.hdrGroupName ? INITIAL_SHIPBOUNDED_CHARGE_HEADER_COL_DEF?.slice() :
                ("SHIPBOUNDEDINPERIOD" === searchCriteria?.hdrGroupName ? INITIAL_SHIPBOUNDEDINPERIOD_CHARGE_HEADER_COL_DEF?.slice() : [])
            )
        );
        return columnDefs;
    }, [searchCriteria?.hdrGroupName]);

    useEffect(() => {
        if (!chargeDataSearchState.selectedChargeHeaderRows ||
            chargeDataSearchState.selectedChargeHeaderRows.length <= 0) {
            //gridRef.current?.gridRef.current.api?.setColumnDefs(getCurrentTableColumnDefs());
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    }, [chargeDataSearchState.selectedChargeHeaderRows, getCurrentTableColumnDefs, onTableSelectionClicked, searchCriteria.hdrGroupName])

    useEffect(() => {
        if (onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();

    }, [onTableSelectionClicked])

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);

        const selectedRows = e.api.getSelectedRows();
        chargeHeaderMainVM.updateSelectedChargeHeaders(selectedRows);
    }, [chargeHeaderMainVM])

    const memoTable = useMemo(() => {
        return <NbisTable
            id={tableId + hdrGroupName}
            key={tableId + hdrGroupName}
            isNewColumnSetting={true}
            columns={getCurrentTableColumnDefs()}
            headerLabel={("DOMESTIC" === searchCriteria?.hdrGroupName ? ChargeConstant.ChargeHeader.DOMESTIC :
                ("SHIPBOUNDED" === searchCriteria?.hdrGroupName ? ChargeConstant.ChargeHeader.SHIP_BOUNDED :
                    ("SHIPBOUNDEDINPERIOD" === searchCriteria?.hdrGroupName ? ChargeConstant.ChargeHeader.SHIP_BOUNDED_PERIOD : [])
                ))}
            data={transferShipboundedRowData(chargeDataSearchState.chargeHeaderEntities ?? [])}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            showUploadIcon={true}
            isScrollHighlighted={true}
            selectionMode={false}
            handleSelectionChanged={handleSelectionChange}
            rowSelection={"multiple"}
            suppressRowClickSelection={false}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)"
            ref={gridRef}
            onRowDoubleClick={(e: any) => { chargeHeaderMainVM.goToChargeDetail(e.data) }}
        />
    }, [chargeDataSearchState.chargeHeaderEntities, chargeHeaderMainVM, getCurrentTableColumnDefs, handleSelectionChange, hdrGroupName, searchCriteria?.hdrGroupName]);


    return <Suspense>
        {memoTable}
    </Suspense>



}

export default memo(ChargeHeaderSearchTablePanel);