import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent, customEventListener } from "helpers/Events";
import _ from "lodash";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { OverflowMenuButton } from "presentation/view/components/OverflowMenuButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton, OverflowingSubMenu } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const ChargeHeaderMaintenanceHeader: React.FC = () => {
    const messageBarVM = useMessageBarVM();
    const [, setIsEditCriteria] = useState(false);
    const [chargeHeaderMaintenanceModel] = useChargeHeaderMaintenanceTracked();
    const headerCons = ChargeConstant.ChargeHeader;
    const vslSummaryConst = ChargeConstant.VesselSummary;
    const ibCntrSummaryConst = ChargeConstant.InboundContainerSummary;
    const delChgSummaryConst = ChargeConstant.DeliveryChargeSummary;
    const chargeHeaderMaintenanceVM = useChargeHeaderMaintenanceVM();
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    const { tempHdrGroupName } = chargeHeaderState.searchCriteria;

    const selectedRows = chargeHeaderState.selectedChargeHeaderRows;
    const selectedRowsCount = selectedRows.length;

    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;

    // Search Criteria Edit
    // const handleCriteriaEditClick = useCallback(() => {
    //     customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_HEADER_EDIT_CRITERIA, { isShowRightPanel: !isEditCriteria });
    //     setIsEditCriteria(!isEditCriteria);
    //     setIsDirectory(false);
    // }, [isEditCriteria]);

    // Directory icon click
    const handleDirectoryClick = async () => {
        customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_HEADER_OPEN_DIRECTORY, { isShowRightPanel: true });
        setIsEditCriteria(true);
    };

    // const handleClose = useCallback(() => {
    //     customDispatchEvent(E_Type_Of_Event.MODAL_EVENT, E_Custom_Dispatch_Event.MODAL_CLOSE, { showChargeSearch: false });
    // }, []);

    const handleSearchClick = useCallback(() => {
        chargeHeaderMaintenanceVM.onSearchClick();
    }, [chargeHeaderMaintenanceVM]);

    const handleSaveSearchPreference = useCallback(() => {
        customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_SAVE_SEARCH, {
            currentSearchPreference: chargeHeaderMaintenanceModel.currentSearchPreference,
            searchCriteria: chargeHeaderMaintenanceModel.searchCriteria,
            enabledSearchCritieria: chargeHeaderMaintenanceModel.enabledSearchCriteria
        });
    }, [chargeHeaderMaintenanceModel.currentSearchPreference, chargeHeaderMaintenanceModel.enabledSearchCriteria, chargeHeaderMaintenanceModel.searchCriteria]);

    const preferenceMenu: OverflowingSubMenu[] = useMemo(() => {
        const menuItemArray: OverflowingSubMenu[] = []
        if (chargeHeaderMaintenanceModel.searchPreferences.length) {
            menuItemArray.push({
                title: 'Open preference',
                submenu: chargeHeaderMaintenanceModel.searchPreferences?.map(searchPreference => ({
                    title: searchPreference.preferenceName,
                    icon: chargeHeaderMaintenanceModel.currentSearchPreference?.key === searchPreference.key ? 'hph-icon Icon-masterbookmark-fill' : '',
                    command: () => customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_HEADER_PREFERENCE_OPEN, { searchPreference: searchPreference }),
                }))
            })
        }

        if (chargeHeaderMaintenanceModel.currentSearchPreference?.userEmail === chargeHeaderMaintenanceModel.userEmail) {
            menuItemArray.push({
                title: 'Save preference',
                command: handleSaveSearchPreference,
            })
        }

        menuItemArray.push({
            title: 'Save as new preference...',
            command: () => customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_HEADER_PREFERENCE_SAVE_NEW, {}),
        });

        const ownDashboards = chargeHeaderMaintenanceModel.searchPreferences?.filter((searchPreference) => searchPreference.userEmail === chargeHeaderMaintenanceModel.userEmail)
        if (ownDashboards.length) {
            menuItemArray.push({
                title: 'Delete preference...',
                command: () => customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_HEADER_PREFERENCE_DELETE, {}),
            })
        }

        return menuItemArray;

    }, [chargeHeaderMaintenanceModel.searchPreferences, chargeHeaderMaintenanceModel.currentSearchPreference, chargeHeaderMaintenanceModel.userEmail, handleSaveSearchPreference]);

    const onChargeHeaderSearchChange = (e: any) => {
        switch (e.detail.action) {
            case E_Custom_Dispatch_Event.CHARGE_HEADER_EDIT_CRITERIA_BACK:
                setIsEditCriteria(e.detail.data.isEdit);
                break;
            case E_Custom_Dispatch_Event.CHARGE_HEADER_HIDE_RIGHT_PANEL:
                setIsEditCriteria(true);
                break;
            case E_Custom_Dispatch_Event.CHARGE_HEADER_PREFERENCE_GET:
                // setChargeHeaderMaintenanceModel(e.detail.data.ChargeHeaderMaintenanceModel);
                break;
            case E_Custom_Dispatch_Event.CHARGE_HEADER_PREFERENCE_CURRENT_UPDATE:
                // setChargeHeaderMaintenanceModel(prev => ({ ...prev, currentSearchPreference: e.detail.data.currentSearchPreference }));
                break;
            // case E_Custom_Dispatch_Event.CONTAINER_DETAIL_SHOW:
            //     setTitle('CONTAINER DETAILS');
            //     setIsList(false);
            //     break;
            // case E_Custom_Dispatch_Event.CONTAINER_DETAIL_HIDE:
            //     setTitle('CONTAINER SEARCH');
            //     setIsList(true);
            //     break;
        }
    }

    useEffect(() => {
        customEventListener(E_Type_Of_Event.CHARGE_HEADER_SEARCH_EVENT, onChargeHeaderSearchChange);
    }, []);

    const overFlowMenu = useMemo(() => <OverflowMenuButton icon='Icon-bookmark' navigationList={preferenceMenu} />, [preferenceMenu]);

    const handleRefreshStateClick = useCallback(() => {
        if (_.isEmpty(selectedRows)) {
            messageBarVM.showError('Please select a record!');
        } else {
            chargeHeaderMaintenanceVM.onRefreshHeaderState(selectedRows).then(() => {
                messageBarVM.showSuccess('Refresh header state successfully.');
                chargeHeaderMaintenanceVM.onSearch(chargeHeaderState.searchCriteria, EMPTY_SEARCH_MODE_ENTITY)
            }).catch((error) => {
                messageBarVM.showError('Refresh header state failure.');
            });
        }
    }, [chargeHeaderMaintenanceVM, chargeHeaderState.searchCriteria, selectedRows, messageBarVM]);

    const handleVesselSummaryClick = useCallback(() => {
        if (selectedRowsCount === 1) {
            chargeHeaderMaintenanceVM.onSelectedChargeHeader(selectedRows[0])
            chargeHeaderMaintenanceVM.goToVesselSummary();
        } else {
            messageBarVM.showError('Please select a record!');
        }
    }, [chargeHeaderMaintenanceVM, messageBarVM, selectedRows, selectedRowsCount]);

    const handleInboundContainerSummaryClick = useCallback(() => {
        if (selectedRowsCount === 1) {
            chargeHeaderMaintenanceVM.onSelectedChargeHeader(selectedRows[0])
            chargeHeaderMaintenanceVM.goToInboundContainerSummary();
        } else {
            messageBarVM.showError('Please select a record!');
        }
    }, [chargeHeaderMaintenanceVM, messageBarVM, selectedRows, selectedRowsCount]);

    const handleDeliveryChargeSummaryClick = useCallback(() => {
        chargeHeaderMaintenanceVM.goToDeliveryChargeSummary();
    }, [chargeHeaderMaintenanceVM]);

    const handleDivertShipClick = useCallback(() => {
        if (selectedRowsCount === 1) {
            chargeHeaderMaintenanceVM.onSelectedChargeHeader(selectedRows[0])
            chargeHeaderMaintenanceVM.toggleDivertShipPanel(chargeHeaderState.isShowDivertShipPanel, selectedRows[0]).then((data) => {
                if (data && data["divertShipValidationFail"]) {
                    const err: string = data["divertShipValidationFail"]?.toString() ?? "";
                    messageBarVM.showError(err.replace("Error:", ""));
                }
            })
        } else {
            messageBarVM.showError('Please select a record!');
        }
    }, [chargeHeaderMaintenanceVM, chargeHeaderState.isShowDivertShipPanel, messageBarVM, selectedRows, selectedRowsCount]);


    return <>
        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            {/* <Breadcrumb> */}
            <HeaderTitle>{headerCons.CHARGE_PROCESSING}</HeaderTitle>
            {/* </Breadcrumb> */}
            {/* <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: headerCons.CHARGE_PROCESSING }]}  onCurrentClick={handleClose}/>
        </Breadcrumb> */}
            <StyledAction className="tm-animated-wrapper">
                <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearchClick} />
                <IconButton fileName='Icon-reload' disabled={false} size='medium' toolTipText={headerCons.REFRESH_HEADER_STATE} toolTipArrow={false} onClick={handleRefreshStateClick} />
                {
                    (allowUpdate) && <IconButton fileName='Icon-feeder-menu' disabled={tempHdrGroupName === 'DOMESTIC'} size='medium' toolTipText={'Divert Ship'} toolTipArrow={false} onClick={handleDivertShipClick} />
                }
                <div className="add-seperator" />
                {
                    (allowUpdate) && <IconButton fileName='Icon-table-view' disabled={tempHdrGroupName === 'DOMESTIC'} size='medium' toolTipText={vslSummaryConst.VESSEL_SUMMARY} toolTipArrow={false} onClick={handleVesselSummaryClick} />
                }
                <IconButton fileName='Icon-docs' disabled={tempHdrGroupName === 'SHIPBOUNDEDINPERIOD' || tempHdrGroupName === 'DOMESTIC'} size='medium' toolTipText={ibCntrSummaryConst.INBOUND_CONTAINER_SUMMARY} toolTipArrow={false} onClick={handleInboundContainerSummaryClick} />
                <IconButton fileName='Icon-layer' disabled={tempHdrGroupName === "SHIPBOUNDED" || tempHdrGroupName === 'DOMESTIC'} size='medium' toolTipText={delChgSummaryConst.DELIVERY_CHARGE_SUMMARY} toolTipArrow={false} onClick={handleDeliveryChargeSummaryClick} />
                {/* <div className="md-header-option" style={{ flexGrow: 0 }}> */}
                {/* {isEditCriteria && <IconButton fileName='Icon-pen' tooltipDisable={false} size="medium" onClick={handleCriteriaEditClick} toolTipText='Edit Criteria' />} */}
                {false && <><div className="add-seperator" />
                    <IconButton fileName='Icon-directory' tooltipDisable={false} size="medium" onClick={handleDirectoryClick} toolTipText="Directory" />
                    {overFlowMenu}</>}
                {/* </div> */}
                {/* <div className={`md-header-action`} style={{ width: '75px' }}>
                <IconButton fileName={isFullScreen ? 'Icon-minimise' : 'Icon-enlarge'} tooltipDisable={false} toolTipText={!isFullScreen ? 'Maximise' : 'Restore'} size="medium" onClick={handleFullScreen} />
                <IconButton fileName="Icon-cross" toolTipText="Close" tooltipDisable={false} size="medium" onClick={handleClose} />
            </div> */}
            </StyledAction>
            {chargeHeaderState.forceRefresh && <></>}
        </Sidebarheader>
    </>
}