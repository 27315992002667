import { ChargeHeaderCriteriaDirectoryAllCheckboxOption, ChargeHeaderCriteriaDirectoryCheckboxOptionList } from "presentation/constant/ChargeHeader/ChargeHeaderCriteriaDirectoryCheckboxOption";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import {useEffect, useRef} from "react";
import {HPHCheckbox} from "veronica-ui-component/dist/component/core";

export const ChargeHeaderSearchCriteriaCheckboxList = () => {
    const [chargeDataSearchState] = useChargeHeaderMaintenanceTracked();
    const chargeDataSearchVM = useChargeHeaderMaintenanceVM();
    const checkBoxRef = useRef<{ [key: string]: HTMLElement | null }>({});

    useEffect(() => {
        chargeDataSearchVM.updateCheckboxRef(checkBoxRef.current);
    }, [chargeDataSearchVM]);

    const highlightCheckboxKey = chargeDataSearchState.highlightedCheckboxKey;
    const checkboxValue = chargeDataSearchState.checkboxValue;

    return <div className='im-charge-data-search-criteria-directory-checkbox-list'>
        <HPHCheckbox label={ChargeHeaderCriteriaDirectoryAllCheckboxOption.name} checked={checkboxValue[ChargeHeaderCriteriaDirectoryAllCheckboxOption.key]} onChange={(e) => chargeDataSearchVM.updateCheckboxValue(e.target.checked, ChargeHeaderCriteriaDirectoryAllCheckboxOption.key)} />
        {
            ChargeHeaderCriteriaDirectoryCheckboxOptionList?.map(item => <div key={item.option.key} className='im-charge-data-search-criteria-directory-checkbox-group'>
                {
                    item.checkboxList?.map(item => <div ref={ref => checkBoxRef.current[item.key] = ref} key={item.key} className={`im-charge-data-search-criteria-directory-checkbox-child ${highlightCheckboxKey === item.key ? 'im-charge-data-search-criteria-directory-checkbox-child-highlighted' : ''}`}>
                        <HPHCheckbox label={item.name} checked={checkboxValue[item.key]} onChange={(e) => chargeDataSearchVM.updateCheckboxValue(e.target.checked, item.key)} />
                    </div>)
                }
            </div>)
        }
    </div>;
};
