import type { CustomCellRendererProps } from "ag-grid-react";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";

const VESSEL_CONSTANT = ChargeDetailConstant.Vessel;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];

export const INITIAL_VESSEL_VOYAGE_MASTER_COL_DEF: any[] = [
    {
        headerName: VESSEL_CONSTANT.CONSORTIUM_CODE,
        field: 'consortiumCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 250,
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: VESSEL_CONSTANT.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 250,
    },
    {
        headerName: VESSEL_CONSTANT.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 250,
    },
    {
        headerName: VESSEL_CONSTANT.HANDLING_TERMINAL,
        field: 'handlingTerminal',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: VESSEL_CONSTANT.VESSEL_NAME,
        field: 'vesselName',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 300,
    },
    {
        headerName: VESSEL_CONSTANT.SOA,
        field: 'soa',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: VESSEL_CONSTANT.LOA,
        field: 'loa',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: VESSEL_CONSTANT.VESSEL_TYPE,
        field: 'vesselType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: VESSEL_CONSTANT.ETD,
        field: 'etd',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        dataType: "date",
        width: 200,
    },
    {
        headerName: VESSEL_CONSTANT.ARR_DATE_TIME,
        field: 'vslStartOpsDateTime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        dataType: "date",
        width: 300,
    },
    {
        headerName: VESSEL_CONSTANT.DEP_DATE_TIME,
        field: 'vslEndOpsDateTime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        dataType: "date",
        width: 300,
    },
    {
        headerName: VESSEL_CONSTANT.SERVICE_CODE,
        field: 'serviceCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}